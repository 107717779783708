// eslint-disable-next-line max-classes-per-file
/* eslint max-classes-per-file: ["error", 2] */

'use strict';

/* eslint function-paren-newline: ["error", "never"] */
define('vb/private/types/dataProviders/dynamicServiceDataProvider',['vb/private/constants',
  'vb/private/log',
  'vbc/private/logConfig',
  'vb/private/utils',
  'vb/helpers/mixin',
  'vb/types/eventTargetMixin',
  'vb/private/types/dataProviderConstants',
  'vb/private/types/dataProviders/serviceDataProviderUtils',
  'vb/private/types/capabilities/noOpFetchFirst',
  'vb/private/types/capabilities/fetchFirst',
  'vb/private/types/utils/serviceDataProviderRestHelperFactory',
], (Constants, Log, LogConfig,
  Utils, Mixin, EventTargetMixin,
  DPConstants, SDPUtils, NoOpFetchFirst,
  FetchFirst, SDPRestHelperFactory) => {
  const SDP_PREFIX = 'sdp-';
  const NOOP_FUNC = () => {};
  const NOOP_SIGNAL = {
    add: NOOP_FUNC,
    addOnce: NOOP_FUNC,
    dispatch: NOOP_FUNC,
    dispose: NOOP_FUNC,
    forget: NOOP_FUNC,
    getNumListeners: NOOP_FUNC,
    halt: NOOP_FUNC,
    has: NOOP_FUNC,
    remove: NOOP_FUNC,
    removeAll: NOOP_FUNC,
    toString: NOOP_FUNC,
  };
  const CRITERION_TYPE = {
    op: 'string',
    attribute: 'string',
    value: 'any',
  };
  CRITERION_TYPE.criteria = [CRITERION_TYPE];

  /**
   * default type definition for ServiceDataProvider
   * @type {{headers: string, uriParameters: string, capabilities: {filter: {operators: string, textFilter: any,
   *   textFilterMatching: {matchBy: string}}, fetchByKeys:
   *   {implementation: string, multiKeyLookup: string}, sort: {attributes: string}, fetchFirst: {implementation:
   *   string}, fetchByOffset: {implementation: string}}, filterCriterion: {op: string, attribute: string, value:
   *   string}, keyAttributes: string, transforms: {request: {filter: string, select: string, query: string, paginate:
   *   string, sort: string, body: string}, response: {paginate: string, body: string}}, pagingCriteria:
   *   {iterationLimit: string, offset: string, size: string, maxSize: string}, body: string, itemsPath: string,
   *   transformsContext: string, endpoint: string, responseType: string, totalSize: string, sortCriteria: [{attribute:
   *   string, direction: string}], mergeTransformOptions: string}}
   */
  const TYPEDEF = {
    body: 'any',
    capabilities: {
      sort: {
        attributes: 'string',
      },
      filter: {
        operators: 'string[]',
        textFilter: 'any',
        textFilterMatching: {
          matchBy: 'string[]'
        }
      },
      fetchByKeys: {
        implementation: 'string',
        multiKeyLookup: 'string',
      },
      fetchByOffset: {
        implementation: 'string',
      },
      fetchFirst: {
        implementation: 'string',
      },
    },
    endpoint: 'string',
    filterCriterion: CRITERION_TYPE,
    headers: 'object',
    itemsPath: 'string',
    keyAttributes: 'any',
    mergeTransformOptions: 'string',
    pagingCriteria: {
      offset: 'number',
      size: 'number',
      maxSize: 'number',
      iterationLimit: 'number',
    },
    responseType: 'any',
    sortCriteria: [
      {
        attribute: 'string',
        direction: 'string',
      },
    ],
    totalSize: 'number',
    transforms: {
      request: {
        paginate: 'string',
        query: 'string',
        filter: 'string',
        sort: 'string',
        select: 'string',
        body: 'string',
      },
      response: {
        paginate: 'string',
        body: 'string',
      },
    },
    transformsContext: 'object',
    uriParameters: 'object',
  };

  const LOGGER = Log.getLogger('/vb/dataProviders/DynamicServiceDataProvider', [
    // Register custom loggers
    {
      name: 'startFetch',
      severity: Constants.Severity.INFO,
      style: LogConfig.FancyStyleByFeature.serviceDataProviderStart,
    },
    {
      name: 'endFetch',
      severity: Constants.Severity.INFO,
      style: LogConfig.FancyStyleByFeature.serviceDataProviderEnd,
    },
  ]);

  /* eslint class-methods-use-this: ["error", { "exceptMethods": ["getDefinitionValue","getIdAttributeProperty",
  "getLifecycleStageChangedSignal","callActionChain","isDisconnected","getTotalSize"]}] */
  class DynamicServiceDataProvider extends Mixin().with(EventTargetMixin) {
    /**
     * @constructor
     * creates a standalone ServiceDataProvider instance that is initialized with the state that the caller provides
     * and returns the instance. The instance is a standalone and is not backed by a variable that manages its
     * state. The instance merely fetches the data from Rest and returns the result.
     *
     * @todo need to work with JET to define a context, if possible, to determine which extension
     * this is being used in (if any). this is needed to restrict access to other extension services.
     *
     * @param dataProviderOptions options used to instantiate the data provider instance
     * @param serviceOptions service options used to initialize RestHelper with
     * @param options.vbContext {object} - options that provide the 'vb' context for the current call (this object
     *                                     is typically provided by a VB API or callback mechanism).
     */
    constructor(dataProviderOptions, serviceOptions, vbContext) {
      super();
      this.id = SDP_PREFIX + Utils.generateUniqueId();
      this.log = LOGGER;
      // whitelist options passed in
      const dpOptions = dataProviderOptions || {};
      /**
       * represents the state of the DySDP when it was created
       * @type {object}
       * @private
       */
      this.state = Object.keys(TYPEDEF).reduce((obj, key) => {
        const o = obj;
        const value = dpOptions[key];
        if (value) {
          o[key] = value;
        }
        return o;
      }, {});

      // totalSize is writable
      Object.defineProperty(this, 'totalSize', {
        value: this.state.totalSize,
        enumerable: true,
        writable: true,
      });

      this.serviceOptions = serviceOptions;
      this.mergedCaps = undefined;
      this.fetchMethodsSetup = undefined;
      // @todo: define extension context @see constructor
      this.createRestHelper = () => SDPRestHelperFactory.get(serviceOptions || dpOptions.endpoint || '', vbContext);
    }

    /**
     * Initializer sets up fetch methods based on capabilities configured.
     */
    init() {
      // TODO: test for entire default value being a constant

      if (!this.fetchMethodsSetup) {
        const sdpValue = this.state;
        const sdpCapsDef = (sdpValue && sdpValue[DPConstants.CAPABILITIES_KEY]) || {};
        const sdpFetchCapsDef = SDPUtils.getConfiguredFetchCapabilities(sdpCapsDef) || {};
        // (1) first check to make sure multiple fetch capabilities are not configured on the same
        // SDP variable.
        if (SDPUtils.hasMultipleFetchCapabilities(sdpFetchCapsDef)) {
          this.log.error('DynamicServiceDataProvider', this.id,
            'is configured with multiple fetch capabilities when only one should be used!');
          return;
        }

        let endpointCaps = {};
        if (this.serviceOptions || this.state.endpoint) {
          const restHelper = this.createRestHelper();
          if (typeof restHelper.retrieveState === 'function') {
            const name = `dynamic:${sdpValue.endpoint}`;
            endpointCaps = restHelper.retrieveState(name, DPConstants.CAPABILITIES_KEY) || {};
            this.log.fine(`capabilities from endpoint ${this.state.endpoint} for DynamicServiceDataProvider ${this.id} is: `, endpointCaps);
          }
        }
        const mergedCaps = Object.assign({}, endpointCaps, sdpCapsDef);
        const fetchCapsDef = SDPUtils.getConfiguredFetchCapabilities(mergedCaps) || {};
        const finalFetchCaps = SDPUtils.getResolvedFetchCapabilities(fetchCapsDef);
        this.mergedCaps = Object.assign(mergedCaps, finalFetchCaps);
        this.log.fine(`merged capabilities for DynamicServiceDataProvider ${this.id} is: `, this.mergedCaps);

        // (2) define fetchByKeys and fetchByOffset implementations on SDP based on configured capabilities.
        SDPUtils.initFetchByKeysMethods(this, finalFetchCaps);
        SDPUtils.initFetchByOffsetMethods(this, finalFetchCaps);
        this.fetchMethodsSetup = true;
      }
    }

    // look up the associated endpoint for capabilities, this work
    // is asynchronous.
    activateAsync() {
      let endpointCapabilities;
      let endpoint = this.state.endpoint;
      if (endpoint) {
        const sdpValue = this.state;
        const uriParameters = Utils.cloneObject(sdpValue.uriParameters);
        const initConf = {};
        const restHelper = this.createRestHelper();
        if (typeof restHelper.retrieveState === 'function') {
          const name = `dynamic:${endpoint}`;
          endpointCapabilities = restHelper.retrieveState(name, DPConstants.CAPABILITIES_KEY);
          if (!endpointCapabilities) {
            this.log.fine(`no cached capability with name ${name}, start getting endpoint capabilities for DynamicServiceDataProvider ${this.id} from endpoint ${endpoint}.`);
            restHelper
              .parameters(uriParameters)
              .initConfiguration(initConf);
            endpointCapabilities = restHelper.getAndStoreCapabilities(name);
          } else {
            this.log.fine(`got cached endpoint capabilities with name ${name} for DynamicServiceDataProvider ${this.id} from endpoint ${endpoint}.`);
          }
        }
      } else {
        this.log.fine(`no endpoint, skip getting endpoint capabilities for DynamicServiceDataProvider ${this.id}.`);
      }
      return Promise.resolve(endpointCapabilities).then((result) => {
        if (result) {
          this.log.fine(`finish getting endpoint capabilities for DynamicServiceDataProvider ${this.id} from endpoint ${endpoint}: `, result);
        }
      }).catch((error) => {
        this.log.warn(`error getting endpoint capabilities: `, error);
      }).finally(() => {
        this.init();
      })
    }

    // eslint-disable-next-line no-unused-vars
    callActionChain(chainLocator, chainParams) {
      throw new Error(`unable to call action chain ${chainLocator.chainId || chainLocator.chain} with the params, ${chainParams} because this feature
        is not supported!`);
    }

    /**
     * Fetches data by iteration, always starting from the first block of data. If a valid endpoint was not provided
     * at instance creation time this method returns a noop fetchFirst implementation.
     *
     * @param { import("ojs/ojdataprovider").FetchListParameters|* } params fetch parameters
     * @return {FetchListAsyncIterable} an AsyncIterable
     * @method
     * @name fetchFirst
     */
    fetchFirst(params) {
      if (this.state.endpoint) {
        const fetchFirst = new FetchFirst(this, params);
        this.log.finer('iterator', fetchFirst.id, 'created for fetchFirst() on SDP:', this.getId());
        return fetchFirst.fetch();
      }
      this.log.info('a valid endpoint was not provided for the ServiceDataProvider', this.id, '. Using a noop'
        + ' implementation');
      return (new NoOpFetchFirst(params)).fetchFirst();
    }

    /**
     * For a feature this method returns the capability supported. This is called by components
     * and consumers of DP implementation.
     *
     * @param {String} feature the capability name - includes 'sort', 'filter', 'fetchByKeys',
     * 'fetchByOffset' and 'fetchFirst'. fetchFirst is defined by VB and by default returns
     * { implementation: 'iteration'} if not set. This must be set if SDP supports other fetch
     * capabilities on the same endpoint as the fetchFirst.
     * @see oj.DataProvider
     * @return {Object} or null if feature is not recognized
     */
     getCapability(feature) {
      const featureCapability = this.mergedCaps[feature];
      return Utils.cloneObject(SDPUtils.getCapabilityByFeature(feature, featureCapability));
    }

    /**
     * Get the variable id as defined in the page model.
     *
     * @final
     * @return {string} The id for this variable
     */
    // eslint-disable-next-line no-unused-vars
    getId() {
      return this.id;
    }

    /**
     * keyAttributes is the only supported idAttribute property.
     * @return {string}
     */
    getIdAttributeProperty() {
      return DPConstants.DataProviderIdAttributeProperty.KEY_ATTRIBUTES;
    }

    /**
     * Returns the state as its value.
     *
     * @final
     * @returns {*}
     */
    getValue() {
      return this.state;
    }

    /**
     * Returns the state as the value in the definition.
     * Note: When dealing with SDP variables the defaultValue is its configured value, different from its value when
     * fully evaluated.
     */
    getDefinitionValue() {
      return this.state;
    }

    /**
     * returns the response type of the instance
     * @returns {*}
     */
    getType() {
      return this.getValue().responseType || DPConstants.DEFAULT_ANY_TYPE;
    }

    /**
     * Returns a noop signal.
     * Note: Callers usually register their listeners to be notified of variable lifecycle stage changes, but since
     * this is a standalone object this is a no op.
     * @returns {Object} dummy Signal
     */
    getLifecycleStageChangedSignal() {
      return NOOP_SIGNAL;
    }

    /**
     * Return the total size of data available, including server side if not local. If a positive number is not set by
     * the fetch call -1 is returned.
     * Note: this is part of the DataProvider API
     *
     * @returns {Promise.<number>} total size of data
     */
    getTotalSize() {
      return Promise.resolve(SDPUtils.getTotalSize(this.getValue().totalSize));
    }

    /**
     * False always because an SDP object unlike a SDP variable has no variable lifecycle.
     * @return {boolean}
     */
    isDisconnected() {
      return false;
    }

    /**
     * Invoke an event on the container that this instance variable belongs to. A standalone object is not part of
     * any VB container and so this method is a noop
     * @param name of the event
     * @param payload payload for the event
     * @param withBubbling whether event needs to bubble
     * @returns {*|Promise}
     */
    // eslint-disable-next-line no-unused-vars
    invokeEvent(name, payload, withBubbling = true) {
      this.log.info('unable to raise a VB event', name, 'with payload', payload, 'because this feature is not'
        + ' supported on standalone service data provider instances!');
    }

    /**
     * Returns a string that indicates if this data provider (canonical size) is empty. Valid values are:
     * "yes": this data provider is empty.
     * "no": this data provider is not empty.
     * "unknown": it is not known if this data provider is empty until a fetch is made.
     */
    isEmpty() {
      const ts = this.getValue().totalSize;
      if (ts === undefined || ts === -1) {
        return DPConstants.DataProviderIsEmptyValues.UNKNOWN;
      }
      if (ts === 0) {
        return DPConstants.DataProviderIsEmptyValues.YES;
      }
      return DPConstants.DataProviderIsEmptyValues.NO;
    }

    /**
     * Sets the total size on the SDP variable instance. This mutates the actual variable
     * value, which is fine, because there can be only one canonical totalSize per SDP instance.
     *
     * @param {number} ts total size of data
     * @instance
     * @private
     */
    setTotalSize(ts) {
      this.getValue().totalSize = ts;
    }
  }

  return DynamicServiceDataProvider;
});

